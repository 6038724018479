
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CONSTANTS } from 'src/app/common/constants/constants';
import { environment } from 'src/app/common/constants/constants';

@Injectable({
  providedIn:'root'
})
export class RefreshTokenService {
  public processing = false;
  myHeader = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  });

  constructor(
    private _http: HttpClient,
    private jwtHelper: JwtHelperService,
  ) { }

  login(Auth: any) {
    const dataJson = JSON.stringify(Auth);
    return this._http.post(environment.AUTH_SERVER + CONSTANTS.ACCOUNT.LOGIN, dataJson, { headers: this.myHeader });
  }

  setToken(token: string) {
    localStorage.setItem('token', token);
    localStorage.setItem('issueat', (Date.now() / 1000).toString());
    localStorage.setItem('user_traits', this.encodeUserTraits(token));
  }

  encodeUserTraits(access_token: any) {
    return btoa(encodeURIComponent(JSON.stringify(this.getDecodedToken(access_token))));
  }

  getDecodedToken(token: string): any {
    try {
      return this.jwtHelper.decodeToken(token)
    } catch (Error) {
      return null;
    }
  }
}