import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingService } from './loading.service';

@Component({
    selector: 'app-loading-service',
    standalone: true,
    imports: [CommonModule],
    templateUrl: './loading-service.component.html',
    styleUrls: ['./loading-service.component.scss'],
})
export class LoadingServiceComponent {
    textLoading = 'Đang tải dữ liệu';
    loading = false;
    constructor(private loadingService: LoadingService) {
        this.loadingService.$displayLoading.subscribe((value: any) => {
            this.loading = true;
            setTimeout(() => {
                this.loading = value;
            }, 800);
        });
    }
}
