import { Routes } from '@angular/router';
import { canActivate } from './auth/auth.guard';
import { EnumRoleUser } from './common/enums/enum-role';
import { P404Component } from './pages/error/404.component';
import { P500Component } from './pages/error/500.component';

export const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./pages/container-layout/container-layout.routes').then((m) => m.routes),
	},
	{
		path: 'admin',
		loadChildren: () => import('./modules/admin/admin.routes').then((m) => m.routes),
		data: {
			title: 'CMS',
			rootLink: '/admin',
			roles: [EnumRoleUser.SUPER_ADMIN, EnumRoleUser.ADMIN_SITE, EnumRoleUser.COMPANY_MANAGER],
		},
		canActivate: [canActivate],
	},
	{
		path: 'setting',
		loadChildren: () => import('./modules/setting/setting.routes').then((m) => m.routes),
		data: {
			title: 'Cài đặt web',
			roles: [EnumRoleUser.SUPER_ADMIN, EnumRoleUser.ADMIN_SITE, EnumRoleUser.COMPANY_MANAGER],
		},
		canActivate: [canActivate],
	},
	{
		path: 'login',
		loadComponent: () => import('./pages/login/login.component').then((m) => m.LoginComponent),
	},
	{
		path: 'signup',
		loadComponent: () => import('./pages/signup/signup.component').then((m) => m.SignupComponent),
	},
	{
		path: '404',
		component: P404Component,
		data: {
			title: 'Page 404',
		},
	},
	{
		path: '500',
		component: P500Component,
		data: {
			title: 'Page 500',
		},
	},
	{ path: '**', component: P404Component },
];
