import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private displayLoading = new Subject();
  $displayLoading = this.displayLoading.asObservable();

  constructor() {}

  setLoading(loading?: any) {
    this.displayLoading.next(loading);
  }
}
