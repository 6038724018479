
import { mergeMap } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpEvent, HttpHandler, HttpInterceptor, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RefreshTokenService } from 'src/app/common/services/refresh-token.service';
import { environment } from 'src/app/common/constants/constants';
import { CONSTANTS } from 'src/app/common/constants/constants';
import { getToken } from 'src/app/auth/auth.role';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector,
    private tokenService: RefreshTokenService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.tokenService.processing) {
      const decodedToken = this.tokenService.getDecodedToken(<string>getToken());
      if (decodedToken) {
        this.tokenService.processing = true;
        const HALF_HOUR = 7200; // 2 hour
        const issuat = parseInt(localStorage.getItem('issueat') || '0', 10);
        const timeRemain = (issuat + (decodedToken.exp - decodedToken.iat) - (Date.now() / 1000));
        if (timeRemain < HALF_HOUR) {
          const http = this.injector.get(HttpClient);
          return http.get(environment.AUTH_SERVER + CONSTANTS.ACCOUNT.REFRESH_TOKEN, {}).pipe(mergeMap((res: any) => {
            // save token
            if (res['access_token']) {
              this.tokenService.setToken(res['access_token']);
              localStorage.setItem('issueat', (Date.now() / 1000).toString());
              this.tokenService.processing = false;
            }
            return next.handle(request);
          }));
        }
      }
    }

    return next.handle(request);
  }
}
