import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import * as auth from '../../auth/auth.role';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LOCAL_FIELD } from 'src/app/common/constants/localstorage';

@Injectable({ providedIn: 'root' })
export class UnauthorizedInterceptor implements HttpInterceptor {
    LIST_EXCEPT_AUTH = [
        'upload.cloudflarestream.com',
        'vimeo.com',
        'amazonaws.com',
        'cloudfront.net',
        'd7q52e96mezq0.cloudfront.net', // practice activity
        'static-data.onluyen.vn' // practice activity
    ];

    LIST_ACCEPT_AUTH = [];

    constructor() {
    }
    noExcept(url: string) {
        return this.LIST_EXCEPT_AUTH.every(exc => {
            return !url.includes(exc);
        });
    }

    yesExcept(url: string) {
        return this.LIST_ACCEPT_AUTH.some(exc => {
            return url.includes(exc);
        });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (auth.isAuthenticated()) {
            if (this.noExcept(request.url)) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${auth.getToken()}`
                    }
                });
            }
            if (this.yesExcept(request.url)) {
                request = request.clone({
                    setHeaders: {
                        Authorization: `Bearer ${auth.getToken()}`
                    }
                });
            }
        }
        const req = request;
        return next.handle(req).pipe(
            catchError((error: any) => {
                if (error instanceof HttpErrorResponse) {
                    if (error.status === 401) {
                        localStorage.setItem(LOCAL_FIELD.LAST_URL, window.location.href);
                        auth.logOut();
                    }
                }
                return throwError(() => error);
            })
        );
    }
}
