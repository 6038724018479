<div class="max-w-[400px] w-full p-4 text-sm">
    @if (
        infoConfirm?.typeModal === 'delete' ||
        infoConfirm?.typeModal === 'confirm'
    ) {
        <div>
            <div class="flex items-start gap-4">
                @if (infoConfirm.typeModal === 'delete') {
                    <div class="p-2 text-error rounded-full bg-red-100">
                        <lucide-icon name="triangle-alert-icon"></lucide-icon>
                    </div>
                }
                @if (infoConfirm.typeModal === 'confirm') {
                    <div
                        class="p-3 text-green-500 rounded-full bg-green-50 relative before:rounded-full before:bg-green-100 before:w-2/3 before:h-2/3 before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2"
                    >
                        <lucide-icon
                            class="relative z-1"
                            name="circle-check-icon"
                        ></lucide-icon>
                    </div>
                }
                <div>
                    <div class="text-base font-semibold">{{ infoConfirm?.title }}</div>
                    <div class="py-2" [innerHtml]="infoConfirm.content"></div>
                </div>
            </div>
        </div>
    }
    <div class="flex gap-4 mt-4">
        @if (!infoConfirm.refuseEvent) {
            <button
                type="button"
                class="flex-1 items-center border border-gray-300 hover:bg-slate-200 px-6 py-1.5 rounded-md"
                (click)="cancel()"
            >
                {{ infoConfirm.nameCancel || 'Thoát' }}
            </button>
        }
        @if (infoConfirm.okEvent && infoConfirm.typeModal === 'delete') {
            <button
                type="button"
                class="flex-1 items-center bg-red-500 hover:bg-red-400 text-white px-6 py-1.5 rounded-md"
                (click)="accept()"
            >
                {{ infoConfirm.nameAccept || 'Xóa' }}
            </button>
        }
        @if (infoConfirm.okEvent && infoConfirm.typeModal !== 'delete') {
            <button
                type="button"
                class="flex-1 items-center border bg-[#004390] text-white hover:text-[#004390] hover:bg-white hover:border hover:border-[#004390] px-6 py-2 rounded-lg"
                (click)="accept()"
            >
                {{ infoConfirm.nameAccept || 'Xác nhận' }}
            </button>
        }
    </div>
</div>
