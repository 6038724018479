import { Component, ContentChild, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ModalCommonService } from '../modal-common/modal-common.service';
import { MODAL_ID } from '../modal-common/modal-id.const';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { ConfirmModal } from './modal-confirm.model';
import { LucideAngularModule } from 'lucide-angular';

@Component({
    selector: 'app-modal-confirm',
    standalone: true,
    imports: [CommonModule, LucideAngularModule],
    templateUrl: './modal-confirm.component.html',
})
export class ModalConfirmComponent implements OnInit, OnDestroy {
    subscription = new Subscription();
    idConfirm = MODAL_ID.COMMON.CONFIRM;
    infoConfirm: ConfirmModal;
    @ContentChild(TemplateRef)
    templateRef?: TemplateRef<any>;

    constructor(private modalCommonService: ModalCommonService) {}

    ngOnInit(): void {
        this.subscription.add(
            this.modalCommonService.getModalData(this.idConfirm).subscribe((res: any) => {
                if (res.data) {
                    this.infoConfirm = res.data;
                    if (!res.data?.typeModal) this.infoConfirm.typeModal = 'confirm';
                }
            }),
        );
    }

    cancel() {
        this.modalCommonService.closeModal(this.idConfirm);
        if (this.infoConfirm?.cancelEvent) {
            this.infoConfirm?.cancelEvent(true);
        }
    }

    remove() {
        this.infoConfirm.refuseEvent(true);
    }

    accept() {
        this.infoConfirm.okEvent(true);
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }
}
