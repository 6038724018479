import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter } from '@angular/router';
import { JwtModule } from '@auth0/angular-jwt';
import {
    AlignJustify,
    BookOpen,
    Building2Icon,
    ChartPie,
    Check,
    ChevronDown,
    ChevronDownIcon,
    ChevronRightIcon,
    ChevronsDownIcon,
    ChevronsLeftIcon,
    ChevronsRightIcon,
    ChevronsUpIcon,
    Church,
    CircleCheckIcon,
    CircleUser,
    Clipboard,
    ClipboardList,
    Clock1,
    CloudDownloadIcon,
    CloudHail,
    CloudOff,
    CloudUploadIcon,
    Dot,
    FlagIcon,
    Image,
    Landmark,
    LucideAngularModule,
    Mail,
    MapPin,
    MessageCircleMore,
    MousePointerClick,
    MoveLeft,
    MoveRight,
    Navigation,
    PanelTopIcon,
    PencilIcon,
    PlusIcon,
    PowerIcon,
    Rocket,
    RocketIcon,
    Router,
    SearchIcon,
    Settings,
    SettingsIcon,
    ShieldCheck,
    ShieldPlus,
    TicketPercent,
    Trash2Icon,
    Truck,
    TvMinimalPlay,
    UserPlus,
    UserPlusIcon,
    UserRound,
    Users,
    UsersIcon,
    XIcon,
    TriangleAlertIcon,
    HouseIcon,
    AsteriskIcon,
    MonitorCogIcon,
    SquarePenIcon,
    UserIcon,
    Copy,
    Info,
    Timer,
    Clock,
    Calendar,
    AtSignIcon,
    PhoneIcon,
    CalendarDaysIcon,
} from 'lucide-angular';
import { ToastrModule } from 'ngx-toastr';
import { routes } from './app.routes';
import { RefreshTokenInterceptor } from './common/interceptors/refresh-token-interceptor.service';
import { UnauthorizedInterceptor } from './common/interceptors/unauthorized-interceptor.service';

export const appConfig: ApplicationConfig = {
    providers: [
        provideRouter(routes),
        provideAnimations(),
        importProvidersFrom(
            HttpClientModule,
            ToastrModule.forRoot({
                positionClass: 'toast-top-right',
                timeOut: 2000,
            }),
            JwtModule.forRoot({
                config: {
                    tokenGetter: tokenGetter,
                },
            }),
            LucideAngularModule.pick({
                Calendar,
                UserRound,
                MoveLeft,
                SearchIcon,
                UserPlus,
                Copy,
                Users,
                Rocket,
                PlusIcon,
                CloudUploadIcon,
                CloudDownloadIcon,
                PowerIcon,
                PencilIcon,
                Trash2Icon,
                ChevronRightIcon,
                ChevronDownIcon,
                Dot,
                Settings,
                FlagIcon,
                ChevronsRightIcon,
                XIcon,
                ChevronsLeftIcon,
                CircleCheckIcon,
                Building2Icon,
                SettingsIcon,
                PanelTopIcon,
                BookOpen,
                ClipboardList,
                ChartPie,
                AlignJustify,
                MoveRight,
                UsersIcon,
                ChevronsUpIcon,
                ChevronsDownIcon,
                MousePointerClick,
                ChevronDown,
                Check,
                Clipboard,
                UserPlusIcon,
                ShieldCheck,
                ShieldPlus,
                MapPin,
                Landmark,
                Church,
                Navigation,
                Clock1,
                CircleUser,
                CloudHail,
                CloudOff,
                Image,
                Mail,
                MessageCircleMore,
                Router,
                RocketIcon,
                Truck,
                TvMinimalPlay,
                TicketPercent,
                TriangleAlertIcon,
                HouseIcon,
                AsteriskIcon,
                MonitorCogIcon,
                SquarePenIcon,
                UserIcon,
                Info,
                Timer,
                Clock,
                AtSignIcon,
                PhoneIcon,
                CalendarDaysIcon,
            }),
        ),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true,
        },
        // { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: UnauthorizedInterceptor,
            multi: true,
        },
        provideAnimationsAsync(),
    ],
};

export function tokenGetter() {
    return localStorage.getItem('token');
}
